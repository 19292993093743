import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import mixpanel from 'mixpanel-browser'

import '@mantine/core/styles.css'

import LayoutPublic from './layout/Layout.public'
import Layout from 'layout/Layout'

import Login from './screens/Login/Login'
import screens from 'screens'

import auth from 'api/auth'

mixpanel.init('', { debug: window.APP_CONFIG.version === '0.0.0' })

const filterByRoles = (list, userRoles) =>
  list.filter((route) => {
    const { permissions, children } = route
    let valid = true

    if (permissions) {
      valid = !!(userRoles && [].concat(permissions).find((r) => userRoles.includes(r)))
    }

    if (valid && children) {
      route.children = filterByRoles(children, userRoles)
    }

    return valid
  })

const Routes = () => {
  const user = toJS(auth.user)

  const children = filterByRoles(screens, user?.['roles'])

  const router = createBrowserRouter([
    {
      path: '/login',
      element: <LayoutPublic />,
      errorElement: <LayoutPublic />,
      children: [
        {
          path: '/login',
          element: <Login />,
        },
      ],
    },
    {
      path: '/',
      element: <Layout />,
      errorElement: <Layout />,
      children,
    },

    {
      path: '*',
      element: <Navigate to="/login" />,
      errorElement: <Layout />,
    },
  ])

  return <RouterProvider router={router} />
}

export default observer(Routes)
