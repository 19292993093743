import djv from 'djv'

export const validate = (schema, data) => {
  const env = new djv()

  env.addSchema('schema', schema)

  const errors = env.validate('schema', data)
  const isValid = !errors

  return { errors, isValid }
}
